<template>
  <b-modal id="ModalReturnsFilter" :title="$t('Opciones avanzadas')" size="lg" hide-footer no-close-on-backdrop centered @close="close">
    <form-render :form.sync="formReturnsFilter" :key="keyFormRenderReturnsFilter" :fields="fields" containerButtonsClass="col-sm-12"
      ref="formRenderReturnsFilter"/>
    <div class="d-flex justify-content-end">
      <b-button class="m-1" variant="success" @click="ok" :disabled="loading.exporting">
        <span class="mb-1">{{$t('Filtrar')}}</span>
      </b-button>
      <b-button class="mt-1 mb-1" variant="success" @click="e => ok(e, true)" :disabled="loading.exporting">
        <span v-if="!loading.exporting" class="mb-1">{{$t('Exportar')}} <feather-icon icon="FileTextIcon" /></span>
        <span class="mt-1 p-2" v-if="loading.exporting"><b-spinner small  /> </span>
      </b-button>
    </div>
    <div>
      <p v-if="downloadLnk" class="text-right">La exportación ha finalizado exitosamente: <a :href="downloadLnk" :download="downloadName">descargar aquí</a></p>
      <p v-if="ErrorDownloadLink" class="text-right">{{errorMsg}}</p>
    </div>
  </b-modal>
</template>
  
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'


export default {
  name: 'ModalReturnsFilter',
  props: ['filter', 'statusDelivery', 'date', 'isPrintReturn'],
  data() {
    return {
      form: {},
      fields: [],
      formReturnsFilter: {},
      downloadLnk: null,
      downloadName:null,
      loading: { exporting: false },
      keyFormRenderReturnsFilter: 0,
      ErrorDownloadLink: false,
      errorMsg: '',
      deliveryStatusOptions: [],
      firstSelection: {
        country: true,
        shipper: true,
        carrier: true
      },
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      countries: 'getCountries'
    })
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    // countries() {
    //   if (this.countries && !!this.countries.length) this.setInitialData()
    // },
    statusDelivery() {
      this.deliveryStatusOptions = this.statusDelivery.map(statusData => ({
        ...statusData,
        text: statusData.name
      }))
      this.keyFormRenderReturnsFilter++
    },
    date() {
      this.formReturnsFilter.date_range = this.date
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.userData = this.$session.get('cas_user')
      this.formReturnsFilter.date_range = this.date
      this.fields = [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: 'Fecha', range: true, containerClass: 'col-sm-12 col-md-6 col-lg-4' },        
        { fieldType: 'FieldSelect', useLabel: true, name: 'country', label: 'País', containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect', change: this.handleCountryChange, options: this.countries, addFlags: true },
        { fieldType: 'FieldSelect', useLabel: true, name: 'place', dependency: 'country', label: 'Comuna', containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect', searchOnType: { fx: e => this.searchPlace(e), nChars: 3, debounce: 600, persistSearch: true } },
        { fieldType: 'FieldInput', name: 'origin_contact_name', label: 'Nombre de cliente', useLabel: false, containerClass: 'col-sm-12 col-md-6 col-lg-4 container-info' },
        { fieldType: 'FieldInput', name: 'origin_contact_email', label: 'Correo', useLabel: false, containerClass: 'col-sm-12 col-md-6 col-lg-4 container-info' },
        { fieldType: 'FieldInput', name: 'origin_contact_phone', label: 'Telefono', useLabel: false, containerClass: 'col-sm-12 col-md-6 col-lg-4 container-info' },
        { fieldType: 'FieldSelect', useLabel: true, name: 'carrier', label: 'Courier', change: this.handleCarrierChange, searchOnType: { fx:  e => this.searchCarrier(e), nChars: 3, debounce: 600, persistSearch: true }, containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect'},
        { fieldType: 'FieldSelect', useLabel: true, name: 'service', dependency: 'carrier',  label: 'Servicio', containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect', searchOnType: { fx: e => this.searchService(e), nChars: 3, debounce: 600, persistSearch: true } }
      ]
      if (!this.isPrintReturn) this.fields.push({ fieldType: 'FieldSelect', useLabel: true, name: 'flow_action', label: 'Estado', multiple: true, options: this.deliveryStatusOptions, containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect' })
      //@if(Auth::user()->role_id == 1 || Auth::user()->role_id == 4 || Auth::user()->storeProfile())
      if (['marketplace', 'superadmin', 'admin'].includes(this.$session.get('cas_user').role) || (this.$session.get('cas_user').profile_type === 2 || this.$session.get('cas_user').special_permissions).includes('store-profile')) {
        this.fields.splice(
          this.getElementIndex(this.fields, 'date_range') + 1,
          0,
          { fieldType: 'FieldSelect', useLabel: true, name: 'shipper', label: 'Empresas', allowIdSearch: true, containerClass: 'col-12 col-md-6 col-lg-4 fieldSelect', searchOnType: { fx: e => this.searchCompany(e), nChars: 3, debounce: 600 }, change: this.handleShipperChange }
        )
        const indexCarrier = this.getElementIndex(this.fields, 'carrier')
        this.fields[indexCarrier].dependency = 'shipper'
      }
      if (!['marketplace', 'superadmin', 'admin'].includes(this.$session.get('cas_user').role)) {
        this.handleShipperChange('ecommerce', this.$session.get('cas_user').organization?.id)
      }
      this.changeTextI18n(this.$session.get('cas_user').country.code, 'place', 'Place_id')
    },
    searchCompany(value) {
      const queryParams = {
        search_by: value
      }
      return this.baseService.callService('getCompaniesPlatform', queryParams)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            const isAdmin = ['superadmin', 'admin'].includes(this.$session.get('cas_user').role)
            if (row.marketplace === 1 && isAdmin) {
              row.name1 = `${row.name1} (M)`
            } else if (isAdmin && row.mkp_name1 !== null) {
              row.name1 = `${row.name1} - ${row.mkp_name1}`
            }
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name1
          })
          return response
        })
        .catch(err => {
          return err
        }) 
    },
    searchPlace(value) {
      if (!this.formReturnsFilter.country && !value) return
      const params = {
        country_id: this.formReturnsFilter.country.id
      }
      const queryParams = {
        str: value
      } 
      return this.baseService.callService('getPlacesCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleShipperChange(name, value) {
      //Limpiar el input de la dependencia
      if (!this.firstSelection.shipper) {
        if (this.formReturnsFilter.carrier) this.clearInput('carrier')
        if (this.formReturnsFilter.service) this.clearInput('service')
      } else {
        this.firstSelection.shipper = false
      }
      if (!value?.id) {
        // Si al balquear el shipper hay un country seleccionado, hace la busqueda por country.
        if (!!this.formReturnsFilter?.country?.id) {
          this.handleCountryChange('country', this.formReturnsFilter.country)
        } 
        return
      }
      this.searchCarrier(null, value.id)
    },
    searchCarrier (searchOnType, company_id = null) {
      const params = {
        company_id: company_id || this.formReturnsFilter.shipper.id
      }
      const queryParams = {
        limit: 10
      }
      if (searchOnType) queryParams.text = searchOnType
      return this.baseService.callService('getCarriersPlatform', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.carrier_name
            row.id = row.carrier_id
          })
          if (searchOnType) {
            return response
          } else {
            this.fields[this.getElementIndex(this.fields, 'carrier')].options = response
            this.keyFormRenderReturnsFilter++
          }          
        })
        .catch(err => {
          console.error(err)
        })  
    },
    /**
    * Como llega paginado no se mostraran todos los servicios al seleccionar el carrier, es por esto se hace una busqueda tambien al escribir
    * @param {string} value valor escrito
    */
    searchService(value) {
      const params = {
        carrier_id: this.formReturnsFilter.carrier.id
      }
      const queryParams = {
        search_by: value
      } 
      return this.baseService.callService('getServiceByCarrier', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    handleCountryChange(name, value) {
      // Si se selecciona el country pero hay un shipper seleccionado, priva la busqueda por shipper, por eso se sale y no hace nada
      if (!!this.formReturnsFilter?.shipper?.id) return
      //Limpiar el input de la dependencia
      !this.firstSelection.country ? this.clearInput('place') : this.firstSelection.country = false
      const indexCarrier = this.getElementIndex(this.fields, 'carrier')
      this.fields[indexCarrier].dependency = false
      if (!value) {
        this.fields[indexCarrier].dependency = 'shipper'
        this.clearInput('carrier')
        this.fields[indexCarrier].options = []
        return
      }
      this.changeTextI18n(value.code, 'place', 'Place_id')
      const params = {
        country_id: value.id
      }
      this.baseService.callService('getCarriersByCountriesPlatform', { simplify: true, limit: params.country_id === 1 ? 150 : 50 }, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.carrier_name
          })
          this.fields[this.getElementIndex(this.fields, 'carrier')].options = response
          this.keyFormRenderReturnsFilter++
        })
        .catch(err => {
          console.error(err)
        })   
    },
    handleCarrierChange (name, value) {
      //Limpiar el input de la dependencia
      if (!this.firstSelection.carrier) {
        if (this.formReturnsFilter.service) this.clearInput('service')
      } else {
        this.firstSelection.carrier = false
      }
      if (!value) return
      const params = {
        carrier_id: value.carrier_id
      }
      this.baseService.callService('getServiceByCarrier', {}, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.carrier_name
          })
          this.fields[this.getElementIndex(this.fields, 'service')].options = response
          this.keyFormRenderReturnsFilter++
        })
        .catch(err => {
          console.error(err)
        })   
    },
    getElementIndex(array, value) {
      return array.findIndex(field => field.name === value)
    },
    clearInput(name) {
      delete this.formReturnsFilter[name]
      this.fields[this.getElementIndex(this.fields, name)].options = []
    },
    /**
     * Cambiar la descripcion del texto por pais
     * @param {string} countryCode Codigo del pais
     * @param {number} elementName nombre del elemento para hacer la busqueda
     * @param {number} key El valor de la key en el json de las transcripciones
     */
    changeTextI18n(countryCode, elementName, key) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const index = this.fields.findIndex(el => el.name === elementName)
      this.fields[index].label = this.$t(texts[key])
    },
    ok(e, fromExport) {
      this.$emit('filter', this.formReturnsFilter, fromExport)
      //Evitar que haga filtro mientras exporta y cierre el modal
      if (fromExport) {
        this.loading.exporting = true
        if (this.ErrorDownloadLink) this.ErrorDownloadLink = false
      } else {
        this.close()
      }
    },
    exportData (allForm) {
      const queryParams = { 
        ...allForm, 
        delivery_type: 'return', 
        export: true, 
        country_code: this.formReturnsFilter.country.code,
        created_at_from: this.$options.filters.dbDateUTCToGMTlocal(allForm.date_range.start, 'YYYY-MM-DD HH:mm:ss'),
        created_at_to: this.$options.filters.dbDateUTCToGMTlocal(allForm.date_range.end, 'YYYY-MM-DD HH:mm:ss')
      }
      delete queryParams.date_range
      this.baseService.callService('getDeliveriesReturn', queryParams, {})
        .then(resp => {
          this.downloadName = resp.data?.filename || 'Devoluciones-'
          this.downloadLnk = resp.data?.file_path
          this.$bvToast.toast('Felicitaciones', {
            title: this.$t('msg-exito-generacion-reporte'),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          this.$bvToast.toast('¡Oops!', {
            title: this.$t('msg-problema-generacion-reporte'),
            variant: 'danger',
            solid: true
          })
          this.errorMsg = err.data?.message || 'Hubo un error con la exportación'
          this.ErrorDownloadLink = true
          this.loading.exporting = false
        })
        .finally(() => {
          this.loading.exporting = false
        })
    },
    getExportDocURl(url) {
      const queryParams = {
        path: url
      }
      this.baseService.callService('getDeliveriesReturnReportURL', queryParams, null, { fullResponse: true })
        .then(({data}) => {
          this.downloadLnk = URL.createObjectURL(data)
          this.$bvToast.toast('Felicitaciones', {
            title: this.$t('msg-exito-generacion-reporte'),
            variant: 'success',
            solid: true
          })
        })
        .catch(err => {
          this.$bvToast.toast('¡Oops!', {
            title: this.$t('msg-problema-generacion-reporte'),
            variant: 'danger',
            solid: true
          })
          this.errorMsg = err.data?.message || 'Hubo un error con la exportación'
          this.ErrorDownloadLink = true
        })
        .finally(() => {
          this.loading.exporting = false
        })
    },
    close() {
      this.$bvModal.hide('ModalReturnsFilter')
    },
    cleanForm() {
      if (this.ErrorDownloadLink) this.ErrorDownloadLink = false
      this.firstSelection = {
        country: true,
        shipper: true,
        carrier: true
      }      
      Object.keys(this.formReturnsFilter).forEach(key =>  {
        delete this.formReturnsFilter[key]
      })
    }
  }

}
</script>

<style></style>
